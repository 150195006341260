@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
body{
  font-family: 'Poppins', sans-serif;
}
.myCard .card{
  border: none !important;
  border-radius: 15px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.myCard .card  p{
  margin-bottom: 0px;
}

.navbar{
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.navbar .navbar-brand img{
  width: 250px;
  height: 50px;
}
@media(max-width:768px){
  .navbar .navbar-brand img{
    width: 150px;
    height: 40px;
  }
}
.whatsApp-Heading h4{
  color: #292828;
  font-weight: 600;
}
.whatsApp-Heading .whatsApp{
  font-family: whatsappFont;
  color: #25D366;
  font-weight: 800;
}
/* ----- Section Page CSS -----*/
.typeWriter{
  background: -webkit-linear-gradient(145deg, #d62aab, #d62aab);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 35px;
  font-family: 'PoppinsBoldMarathi';
}
.typeWriter-2{
  background: -webkit-linear-gradient(145deg, #d62aab, #d62aab);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 35px;
  font-family: 'Yantramanav', sans-serif;
}

/* .home-section{
  height: 100%;
} */
.home-section .alert{
  border: 2px solid rgb(206, 204, 204);
  border-radius: 25px;
   width: max-content;
   padding: 10px 20px;
   color: gray;
   font-size: 14px;
}

.home-section .inputRow{
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px 0px;
  padding: 25px 20px;
  border-radius: 15px;
  width: 75%;
}
@media(max-width:992px){
    .home-section .inputRow{
      width: 90%;
    }
    .typeWriter{
      font-size: 30px;
    }
}
.home-section .inputRow input,
.home-section .inputRow select{
  border-radius: 10px;
}
.home-section .btn-join{
  /* box-shadow: 0 3px 5px 0 rgb(47 85 212 / 30%); */
  color: white;
  background: -webkit-linear-gradient(180deg,#f06ccf, #cc21a1);
  border-radius: 15px !important;
}
/* --- ERROR Showing Modal CSS ------*/

.modal .close{
  color: white;
  background: -webkit-linear-gradient(145deg, #d62aab,  #d62aab);
}

/* ------ LOGIN PAGE CSS ------*/
.home-section, 
.login-main{
  /* background: url('./asset/backGround.jpg') no-repeat center center fixed; */
  background-size: cover;
  height: 85vh;
}


.PropertyScroll h5{
  font-weight: 600;
  margin: 0px;
}
.PropertyScroll p{
  margin-bottom: 0px;
}

.PropertyScroll .icon{
  color: #fbcc34;
}

.btn-submit,
.btn-logout{
  border: 0px;
  border-radius: 8px;
  background: linear-gradient(175deg,#fff702, #fbcc34);
  color: black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  font-weight: 500 !important;
  padding: 6px 16px !important;
  transition: 0.3s;
  }

 .btn-submit:focus,
 .btn-logout:focus{
    outline: none !important;
    border: none !important;
    box-shadow:none;
   }

  .btn-logout:hover{
    transform: translateX(-10px);
  }
 .btn-submit:hover{
    transform: scale(1.05);
    color: black;
  }

.sendMsg{
  height: 90vh;
  width: 100%;
  display: flex; 
  align-items: center;
  flex-direction: column;
  /* background: url('./asset/backGround.jpg') center center no-repeat ; */
  background-size: cover;
}

.sendMsg .contact-card{
  border-radius: 10px;
  background: #ffffff !important;
  color: black;
  border: none;
  box-shadow: rgba(99, 99, 231, 0.3) 0px 5px 20px 5px;
}

.grpImg{
  width: 600px;
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .grpImg{
    width: 100%;
    margin-bottom: 50px;
  }
  .contact-card{
    margin-top: 6%;
    margin-bottom: 60%;
    margin-left: 10px;
    margin-right: 10px;
  }
  
}
footer{
  background: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px; */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

footer p{
color: black;
  font-size: 15px;
  font-weight: 500;
}



.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 100px;
  height: 30px;
  padding: 3px;
  margin: 0 10px 10px 0;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
}
.switch-label:before, .switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}
.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaaaaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
  content: attr(data-on);
  left: 8px;
  color: #FFFFFF;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
   background: #E1B42B;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}
.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  height: 28px;
  background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.switch-input:checked ~ .switch-handle {
  left: 74px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
/* Transition
    ========================== */
.switch-label, .switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

body{
  font-family: 'Poppins', sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
}

html {
    --scrollbarBG: rgb(227, 226, 226);
    --thumbBG: #FFF500;
  }
  body::-webkit-scrollbar {
    width: 10px;
  }

  body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 2px;
    border: 1px solid var(--scrollbarBG);
  }


.myCard::-webkit-scrollbar{
	width: 6px;
	background-color: rgb(255, 234, 0);
  border-radius: 12px !important;
}

.myCard::-webkit-scrollbar-thumb{
	background-color: black;
  border-radius: 12px;
}

.btn-toggle:focus  {
  padding: 5px 10px;
/* background-color: #FFF500 !important; */
background: linear-gradient(175deg,black, #FFF500);

color:white;
border-radius: 12px;
}
.btnActive{
  /* background-color: #5b4ce2; */
  padding: 5px 10px;
  border: none;
/* background-color: #FFF500 !important; */
background: linear-gradient(175deg,black, #FFF500);

color:white;
border-radius: 12px;
}
.btnNormal{
  padding: 5px 10px;
  /* background-color: #cc21a1 !important; */
  border: none;

  color:black;
  border-radius: 12px;
}

/* -------- Login CSS ---------------*/
.login{
  /* background: url('./Assests/background-image.jpg'); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background: #f8f6f6;
}
.login .card{
  border: 0px;
  border-radius: 15px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  /* width:40%; */
}

 /* .login form input{
  border: 1px solid red;
 }
 .login form span.input-group-text{
  border:1px solid red !important; 
 } */

.login .heading{
/* background: linear-gradient(175deg,black, #FFF500) !important; */
  color: #000 !important;
  font-weight: 700;
}

